import './home.css';
import { useEffect, useState } from "react"
import 'material-symbols/rounded.css';

const addSecondsToCurrentTime = (secondsToAdd) => {
    let currentTime = new Date();
    currentTime.setSeconds(currentTime.getSeconds() + secondsToAdd);
    return currentTime.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
};

const beautifyDate = (seconds) => {
    return new Date(seconds * 1000).toLocaleString('fr-FR', { day: 'numeric', month: 'numeric', hour: 'numeric', minute: 'numeric' }).replace(" ", " à ").replace(":", "h");
};

async function getTorrentsInfos() {
    try {
        const res = await fetch("https://qback.qor.us:25600/qbit")
        const body = await res.json()
        const ret = []

	if (body.forEach) {
            body.forEach(x => {
            	ret.push(Entry({
                	title: x.name,
                	start: x.added_on,
                	dlSpeed: x.dlspeed,
                	progress: x.progress,
                	end: x.eta
                }))
            })
	}
	console.log("Data received : " + ret)
        return ret
    } catch (err) {
        console.error("Failed to fetch : " + err)
        return []
    }
    
}

function Entry({title, start, dlSpeed, progress, end}) {   
    const progressTimeHundred = progress * 100;
    const dlSpeedInMbps = dlSpeed / 1000000

    if (end === 8640000) {
        end = "En pause"
    } else {
        end = "Fin à " + addSecondsToCurrentTime(end)
    }
    
    return (
        <>
            <div id="w-node-_33fbd135-4573-9379-1d02-9152eccb893a-2ab9ae95" class="card card-downloaded">
            <h3 id="w-node-_37c2c0d0-b4ab-512a-6041-dfde9b2edb1b-2ab9ae95" class="card-heading">{title}</h3>
                <div class="card-info">Ajouté le {beautifyDate(start)} - Vitesse téléchargement : {dlSpeedInMbps.toFixed(2)} Mbps</div>
                <div class="progress-bar-wrapper">
                    <div class="progress-bar-info">{progressTimeHundred.toFixed(1)}%</div>
                    <div class="progress-bar-track">
                        <div class="progress-bar" style={{ width: `${progressTimeHundred}%` }}></div>
                    </div>
                    <div class="progress-bar-info">{end}<br/></div>
                </div>
            </div>
        </>
    )
}

function cochon({test}) {
    return (
        <>
            <div id="w-node-_45f6e94f-aa0a-f0f6-6241-c058f6b516c9-2ab9ae95" class="card card-downloading">
                <div class="card-div">
                    <div id="w-node-_092302f6-2c3b-2508-5337-eace91bc4da6-2ab9ae95" class="card-image-div">
                        <img src="https://cdn.prod.website-files.com/661bf25d43cf8a7c2ab9ae8e/670bba30e809b53b5f94e3c6_18366630.jpg" loading="lazy" alt="" class="card-image" />
                    </div>
                    <div id="w-node-_35291136-4777-879c-c43a-63879fc0af88-2ab9ae95" class="card-infos">
                        <h1 class="card-heading card-heading-downloaded">Le seigneur des anneaux : le retour du roi en calice 4160p</h1>
                        <div class="card-info card-info-downloaded">Taille : 26.29 Gb - Durée : 4h31m</div>
                        <div class="card-delete-div">
                            <a href="#" class="card-delete-button w-button">Supprimer le film</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const Home = () => {
    const [torrents, setTorrents] = useState(null)
    useEffect(() => {
        const update = async () => {
            getTorrentsInfos()
            .then(res => setTorrents(res))
            .catch(err => {
                setTorrents([])
                console.log(err)
            })
        }

        update()
        setInterval(update, 1000);
    }, [])
    return (
        <>
            <html data-wf-domain="qor.us">
            <head>
                <meta charset="utf-8" />
                <title>Qorus</title>
                <meta content="width=device-width, initial-scale=1" name="viewport" />
            </head>

            <body class="body">
                <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar w-nav">
                    <div class="navbar-container w-container">
                        <h1 class="brand">LVX</h1>
                        <nav role="navigation" class="nav-menu w-nav-menu">
                            <a href="#" class="nav-link w-nav-link">Accueil</a>
                            <a href="#" class="nav-link w-nav-link">Admin</a>
                        </nav>
                        <div class="w-nav-button">
                            <div class="w-icon-nav-menu"></div>
                        </div>
                    </div>
                </div>
                <section class="section">
                    <div class="w-layout-blockcontainer cards-container w-container">
                        <div class="downloading">
                            <div class="card-head">
                                <h1 class="heading">En téléchargement</h1>
                                <div id="w-node-_2a7582e2-4ac3-2baa-7bdb-6ddad19a319a-2ab9ae95" class="search-block w-form">
                                    <form id="email-form" name="email-form" data-name="Email Form" method="get" class="search-form" data-wf-page-id="661bf25d43cf8a7c2ab9ae95" data-wf-element-id="2a7582e2-4ac3-2baa-7bdb-6ddad19a319b">
                                        <div class="search-wrapper">
                                            <div class="search-icon-wrapper"><input class="search-input-icon w-input" maxlength="256" name="search-input-2" data-name="Search Input 2" placeholder="Rechercher" type="text" id="search-input-2" />
                                                <div class="search-icon w-embed"><svg width="420" height="420" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11 2C15.968 2 20 6.032 20 11C20 15.968 15.968 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2ZM11 18C14.867 18 18 14.867 18 11C18 7.132 14.867 4 11 4C7.132 4 4 7.132 4 11C4 14.867 7.132 18 11 18ZM19.485 18.071L22.314 20.899L20.899 22.314L18.071 19.485L19.485 18.071V18.071Z" fill="currentColor"/>
                                                </svg></div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="success-message w-form-done"></div>
                                    <div class="error-message w-form-fail"></div>
                                </div>
                            </div>
                            <div class="cards-downloading">
                                {torrents}
                            </div>
                        </div>
                    <div class="downloaded">
                        <div class="card-head">
                            <h1 class="heading">Medias dans la bibliotèque</h1>
                                <div id="w-node-_478d0f8b-351e-090d-834b-4d2311c5a583-2ab9ae95" class="search-block w-form">
                                    <form id="email-form" name="email-form" data-name="Email Form" method="get" class="search-form" data-wf-page-id="661bf25d43cf8a7c2ab9ae95" data-wf-element-id="478d0f8b-351e-090d-834b-4d2311c5a584">
                                    <div class="search-wrapper">
                                        <div class="search-icon-wrapper"><input class="search-input-icon w-input" maxlength="256" name="search-input" data-name="search-input" placeholder="Rechercher" type="text" id="search-input" />
                                            <div class="search-icon w-embed"><svg width="420" height="420" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11 2C15.968 2 20 6.032 20 11C20 15.968 15.968 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2ZM11 18C14.867 18 18 14.867 18 11C18 7.132 14.867 4 11 4C7.132 4 4 7.132 4 11C4 14.867 7.132 18 11 18ZM19.485 18.071L22.314 20.899L20.899 22.314L18.071 19.485L19.485 18.071V18.071Z" fill="currentColor"/>
                                            </svg></div>
                                        </div>
                                    </div>
                                    </form>
                                    <div class="success-message w-form-done"></div>
                                    <div class="error-message w-form-fail"></div>
                                </div>
                            </div>

                        <div class="cards-downloaded">
                        </div>

                    </div>
                </div>
                </section>
                <section class="footer">
                    <div class="w-layout-blockcontainer container w-container"></div>
                </section>
            </body>
            </html>
        </>
    );
};

export default Home;
